<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>上链企业信息统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="">
      <div class="echarts" style="margin: auto 0">

        <el-row class=" c999 f14">
          <div class="col-4" :class="{'greenBG':val1 == 'platformEnterprise'}" @click="getList('platformEnterprise')">
            入驻平台企业信息<p class="mt5"><span class="c333 f25 fb mr5">{{data1.platformEnterprise_count}}</span>家</p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'enterprisesEnteringSichuan'}"
            @click="getList('enterprisesEnteringSichuan')">
            入川首站企业<p class="mt5"><span class="c333 f25 fb mr5">{{data1.enterprisesEnteringSichuan_count}}</span>家</p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'storageService'}" @click="getList('storageService')"> 贮存服务企业<p
              class="mt5"><span class="c333 f25 fb mr5">{{data1.storageService_count}}</span>家
            </p>
          </div>
        </el-row>
      </div>
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="page" class="w" status-icon style="text-align: left">
          <el-col :span="4">
            <el-form-item size="small">
              <el-input placeholder="请输入名称" v-model="page.company_name" clearable class="input-with-select"
                @clear="onSearch">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="small">
              <el-button type="primary" @click="onSearch" class="general_bgc general_border cfff">筛选</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list  mt20">
        <div class="title f15">
          <p><span class="triangle-right fl ml10 mr10"></span>统计明细表</p>
          <!-- <el-button type="primary" v-if="loading==false" size="small" @click="DownExcel">导出数据</el-button>
          <el-button v-else-if="loading==true" type="info" disabled size="small">导出数据</el-button> -->
        </div>
        <div class="pannerArea mt15">
          <ul>
            <el-row class="w area-title tl pl10 pr10 flex" style="text-align: center">
              <el-col :span="5" class="omg" style="text-align: left">名称</el-col>
              <el-col :span="5">统一社会信用代码</el-col>
              <el-col :span="3" v-if="val1==='platformEnterprise'">经营储存条件</el-col>
              <el-col :span="5" v-if="val1==='platformEnterprise'">经营商品类型</el-col>
              <el-col :span="3">许可证</el-col>
              <el-col :span="3">备案证</el-col>
              <el-col :span="6">经营地址</el-col>
              <el-col :span="6">主体所在区域</el-col>
              <el-col :span="4">登记人及联系方式</el-col>
              <el-col :span="4">登记时间</el-col>
              <!-- <span class="iconAdd el-icon-arrow-right c-trans "></span> -->
            </el-row>
            <div v-if="tableData.length>0">
              <li v-for="(item,index) in tableData" :key="index" class="">
                <div>
                  <el-row class="w  tl pl10 pr10 flex">
                    <el-col :span="5"  style="text-align: left;">
                      <div>
                      {{item.company_name}}
                      </div>
                    </el-col>
                    <el-col :span="5" v-if="item.credit_code!=''">
                      <p> {{item.credit_code}}</p>
                      <el-button type="primary" @click="imageView(item,'营业执照')" size="small"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true}" class="images">
                          <img :src="item.businessLicense" :key="item.businessLicense" width="50px" height="20px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="5" v-else>
                    </el-col>
                    <el-col :span="3" style="text-align: center"
                      v-if="item.storage_conditions!==''&&val1==='platformEnterprise'">
                      【{{item.storage_conditions}}】
                    </el-col>
                    <el-col :span="3" style="text-align: center;padding-top: 30px;"
                      v-else-if="val1==='platformEnterprise'">
                      【无】
                    </el-col>
                    <el-col :span="5" style="text-align: center"
                      v-if="item.commodity_type!==''&&val1==='platformEnterprise'">
                      【{{item.commodity_type}}】
                    </el-col>
                    <el-col :span="5" style="text-align: center;padding-top: 30px;"
                      v-else-if="val1==='platformEnterprise'">
                      【无类型】
                    </el-col>
                    <el-col :span="3" class=" omg" v-if="item.record_code!==''">
                      {{item.record_code}}
                      <br>
                      <el-button type="primary" @click="imageView(item,'许可证')" size="small"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true}" class="images">
                          <img :src="item.permit" :key="item.permit" width="50px" height="20px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="3" class=" omg" style="text-align: center;" v-else-if="item.company_type_id==16">
                      第三方检测</el-col>
                    <el-col :span="3" class=" omg" style="text-align: center;padding-top: 30px;" v-else>无</el-col>
                    <el-col :span="3" class=" omg" v-if="item.recordCertificate!==''">
                      {{item.recordCertificate}}
                      <br>
                      <el-button type="primary" @click="imageView(item,'备案证')" size="small"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true}" class="images">
                          <img v-for="src in item.imageUrl" :src="src" :key="src" width="50px" height="20px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="4" class=" omg" style="text-align: center;padding-top: 30px;" v-else>无</el-col>
                    <el-col :span="6" style="text-align: center">
                      <div>
                        {{item.businessAddress}}
                      </div>
                    </el-col>
                    <el-col :span="6">{{item.subjectPosition}}</el-col>
                    <el-col :span="4" v-if="item.user_name!==''" class="fl tc">
                      <div>{{item.user_name}}</div>
                      <div>{{item.user_mobilephone}}</div>
                    </el-col>
                    <el-col :span="4" style="padding-top: 30px;" class="tc" v-else>无</el-col>
                    <el-col :span="4">{{item.add_time}}</el-col>
                  </el-row>
                </div>
              </li>
            </div>
            <el-row v-else class="w tc pl10 mb20 flex text-no">
              <el-col :span="24" class="p20">
                暂无数据
              </el-col>
            </el-row>
          </ul>
        </div>
        <div class="i-page fr disib mt20">
          <el-pagination background :page-size='10' layout="total,prev, pager, next" :total="total"
            :current-page="currentPage" @current-change='currentChange'>
          </el-pagination>
        </div>
      </div>
      <el-image-viewer style="z-index: 9999" v-if="showViewer" :on-close="closeViewer" :url-list="[accessory_url]" />
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";

import { local } from "../../../util/util";

import ElImageViewer from "element-ui/packages/image/src/image-viewer";

let option;
export default {
  components: {
    ElImageViewer,
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        2: '四川省小经营店登记证（流通）',
        5: '食品经营许可证（流通）',
        13: '农产品生产企业',
        1: '四川省小作坊登记证',
        18: '食品经营许可证（生产）',
        15: '市场开办方',
        16: '贮存服务提供者',
        3: '食品经营许可（餐饮）',
        4: '四川省小经营店登记证（餐饮）',
        19: '运输公司',
        17: '第三方检测单位',
      }
      return statusMap[status]
    },

  },
  data () {
    return {
      imageUrl: [],
      page: {
        quarantine_cert_number: '',
        company_name: '',
        pager_offset: "0",
      },
      tableData: [],
      bizList: [],
      userData: {},
      data1: '',
      timer: '',
      currentPage: 1,
      total: 0,
      currentIdx: '',
      val1: 'zpc',
      showViewer: false,
      accessory_url: '',//显示的地址
      list1: '',
      showBtn: false,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.page.search_time_end) {
            return time.getTime() > new Date(this.page.search_time_end).getTime()
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
    };
  },
  computed: {},
  mounted () {
    this.userData = local.get("user");
    this.init(this.page);
    this.getList('platformEnterprise')
  },
  methods: {
    imageView (item, type) {
      let param = { company_id: item.company_id.toString(), type: type }
      axios.get('/pc/onChainEnterpriseInformation/url', param).then(res => {
        res = res.data;
        if (res == null) {
          alert("该企业没有上传：" + type);
        }
        this.accessory_url = res.accessory_url;
        if (this.accessory_url != '')
          this.showViewer = true;
        else
          alert("该企业没有上传：" + type);
      })
    },
    closeViewer () {
      this.showViewer = false;
    },
    init (params) {
      axios.get('/pc/onChainEnterpriseInformation/data-statistics', params).then((v) => {
        this.data1 = v.data
      })
    },
    getList (val) {
      var that = this;
      that.currentPage = 1
      that.page.company_name = ''
      that.val1 = val
      that.page.pager_offset = '0'
      that.getData(val)
    },
    getData (params) {
      var that = this;
      axios.get('/pc/onChainEnterpriseInformation/' + params + '/list', this.page).then((v) => {
        if (params === 'platformEnterprise') {
          that.tableData = v.data.platformEnterprise_data;
          that.total = v.data.platformEnterprise_count;
        }
        else if (params === 'enterprisesEnteringSichuan') {
          that.tableData = v.data.enterprisesEnteringSichuan_data;
          that.total = v.data.enterprisesEnteringSichuan_count;
        } else if (params === 'storageService') {
          that.tableData = v.data.storageService_data;
          that.total = v.data.storageService_count;
        }
      }).catch(() => {
      })
    },
    // 分页
    currentChange (page) {
      var that = this;
      that.currentPage = page;

      that.page.pager_offset = String((page - 1) * 10)
      that.getData(that.val1)
    },
    // 搜索
    onSearch () {
      this.getData(this.val1)
    },
  },

};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;

  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .echarts {
    .greenBG {
      background-color: #f5f5f5;
    }

    .col-4 {
      float: left;
      width: 33.33%;
      border: 1px solid #ccc;
      border-right: none;
      padding: 10px;
    }

    .col-4:last-child {
      border-right: 1px solid #ccc;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .triangle-right {
      display: inline-block;
      vertical-align: bottom;
      width: 0;
      height: 0;
      border-width: 10px 0px 10px 16px;
      border-style: solid;
      border-color: transparent transparent transparent #ff992c;
    }
  }

  .pannerArea {
    ul {
      .area-title {
        .el-col {
          height: 60px;
          border-right: 1px solid #e6ecf6;
          line-height: 60px;
          padding-left: 10px;
        }

        .el-col:last-child {
          border-right: none;
        }

        background-color: #f5f5f5;
        border: 1px solid #e6ecf6;
        display: flex;
        align-items: center;
      }

      li {
        list-style: none;

        .el-row {
          .el-col {
            height: 80px;
            border-right: 1px solid #e6ecf6;
            line-height: 30px;
            padding-left: 10px;
          }
          .el-col:last-child {
            border-right: none;
          }
          background: #fff;
          border: 1px solid #e6ecf6;
          cursor: pointer;
          font-size: 14px;
          border-top: none;
          display: flex;
          align-items: center;
        }

        .el-form {
          padding-top: 10px;
          display: inline-block;
          width: 100%;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }

  .text-no {
    border: 1px solid #f5f5f5;
    border-top: none;
  }

  /deep/ .el-table--border {
    border-top: none;
  }

  /deep/ .el-table .el-form-item {
    margin-bottom: 0px;
  }

  /deep/ .el-checkbox__label {
    display: none;
  }
}
</style>
